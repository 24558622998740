import React from 'react';
import {graphql} from "gatsby";
import Img from "gatsby-image";
import tw, {styled, css, theme, GlobalStyles} from 'twin.macro';
// @ts-ignore
import loadable from '@loadable/component';

import {IPostPage} from "@/props";
import Helmet from "react-helmet";

const Seo = loadable(() => import ("@/components/seo"));
const Nav = loadable(() => import ("@/components/nav"));
const Hero = loadable(() => import ("@/components/pages/other/hero"));
const Features = loadable(() => import ("@/components/features"));
const Faq = loadable(() => import ("@/components/faq"));
const Footer = loadable(() => import ("@/components/footer"));
const Theme = loadable(() => import ("@/components/theme"));
const StudyCta = loadable(() => import ("@/components/study_cta"));

const Wrapper = styled.div(
	() => [
		tw``
	]
);

const Article = styled.div(
	() => [
		tw`prose prose-lg text-gray-500 overflow-hidden mx-auto`,
		css`
			min-height: 800px;
			
			h3:not([class]) {
				${tw`text-xl leading-6 font-bold text-primary-background`}
				&::after {
					${css`content: '';`}
					${tw`border-b-2 block mt-2`}
				}
			}
			
			h4:not([class]) {
				${tw`text-gray-900`}
			}
			
			a:not([class]) {
				${tw`text-primary-500 hover:text-primary-300 underline`}
			}

			p.para {
				${tw`py-2 text-gray-900`};
			}
			
			img:not([class]) {
				${tw`rounded-lg object-contain`}
			}
			
			.gatsby-resp-image-link {
				${tw`block relative w-full`}
			}
			
			.gatsby-resp-image-background-image {
				${tw``}
			}
			
			.gatsby-resp-image-wrapper {
				${css`padding: 10px!important;`}
				${tw`block  relative w-full`}
			}
			
			pre {
				${tw`font-mono text-xs`}
			}
			
			code {
				${tw`font-mono bg-red-100 rounded-sm`}
				&::after {content: ""}
				&::before {content: ""}
				padding: 2px 4px;
			}
			
			.table-of-contents {
				ul {
					li {
						${tw`m-0 `}
						ul {
							${tw`m-0 mt-1 mb-2`}
						}
						
						p {
							${tw`m-0 `}
						}
						
						a {
							${tw`no-underline`}
							&:hover {
								${tw`underline`}
							}
						}
					}
				}
			}
		`
	]
);

interface Props extends IPostPage {

}

const PostTemplates: React.FunctionComponent<Props> = (props: Props) => {
	const html = `${props.data.post?.html}`;
	const hSplit = html.split(`<!-- excerpt -->`);
	const content = hSplit.length > 1 ? hSplit[1] : hSplit[0];

	return (
		<Theme>
			<GlobalStyles/>
			<Helmet bodyAttributes={{class: 'font-body'}}/>
			<Seo
				title={props.data.post?.frontmatter?.title}
				description={props.data.post?.excerpt}
				{...props}
			/>

			<Wrapper>
				<div className="bg-primary-background">
					<Nav/>
					<Hero
						title={props.data.post?.frontmatter?.title || ""}
						subtitle={props.data.post?.excerpt || ""}
						indicator={`/${props.data.post?.frontmatter?.category || "posts"} [${props.data.post?.frontmatter?.date}]`}
						illustration={props.data.post?.frontmatter?.hero?.childImageSharp.fluid && (
							<div className="max-w-xs shadow-2xl flex absolute right-0 w-full">
								<Img
									fluid={props.data.post?.frontmatter?.hero?.childImageSharp.fluid}
									style={{maxHeight: "100%"}}
									imgStyle={{objectFit: "contain"}}
									className={"w-full rounded-lg"}
									fadeIn={true}
								/>
							</div>
						)}
					/>
				</div>

				<div className="relative py-16 overflow-hidden">
					<div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
						<div className="relative h-full text-lg max-w-prose mx-auto">
							<svg className="absolute top-12 left-full transform translate-x-32" width="404"
								height="384" fill="none" viewBox="0 0 404 384">
								<defs>
									<pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20"
										    height="20" patternUnits="userSpaceOnUse">
										<rect x="0" y="0" width="4" height="4" className="text-gray-200"
											 fill="currentColor"/>
									</pattern>
								</defs>
								<rect width="404" height="384"
									 fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"/>
							</svg>
							<svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
								width="404" height="384" fill="none" viewBox="0 0 404 384">
								<defs>
									<pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20"
										    height="20" patternUnits="userSpaceOnUse">
										<rect x="0" y="0" width="4" height="4" className="text-gray-200"
											 fill="currentColor"/>
									</pattern>
								</defs>
								<rect width="404" height="384"
									 fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
							</svg>
							<svg className="absolute bottom-12 left-full transform translate-x-32" width="404"
								height="384" fill="none" viewBox="0 0 404 384">
								<defs>
									<pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20"
										    height="20" patternUnits="userSpaceOnUse">
										<rect x="0" y="0" width="4" height="4" className="text-gray-200"
											 fill="currentColor"/>
									</pattern>
								</defs>
								<rect width="404" height="384"
									 fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"/>
							</svg>
						</div>
					</div>

					<div className="relative px-4 sm:px-6 lg:px-8">
						<Article>
							<div dangerouslySetInnerHTML={{__html: content}}/>
						</Article>
					</div>
				</div>

				<div className="relative py-20">
					<div className="absolute inset-0 flex flex-col">
						<div className="flex-1 bg-bg-background "/>
					</div>
					<div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
						<StudyCta/>
					</div>
				</div>

				<div className="max-w-2xl lg:py-24 py-12 mx-auto pb-16 mt-12 sm:pb-0 sm:px-6 lg:max-w-screen-xl">
					<div className="max-w-4xl mx-auto text-center">
						<h2 className="text-3xl leading-9 font-extrabold text-primary-background">
							Unlimited users and zero restrictions
						</h2>
						<p className="mt-4 text-lg leading-7 text-gray-500">
							Your VM is like any other server and it can be upgraded to fit any scenario.
							We install only open source software which has no limitations in place, it is
							upgradable and highly customizable
						</p>
					</div>
					<Features/>
				</div>

				<div className="bg-bg-background mt-20">
					<div className="max-w-2xl mx-auto py-16 sm:py-24 sm:px-6 lg:max-w-screen-xl lg:px-8">
						<Faq/>
					</div>
				</div>

				<Footer/>
			</Wrapper>
		</Theme>
	);
};

export default PostTemplates;

export const pageQuery = graphql`
	query($slug: String!) {
		post: markdownRemark(fields: {slug: {eq: $slug}}) {
			html
			excerpt(pruneLength: 250)
			frontmatter {
				title
				date(formatString: "MMMM DD, YYYY")
				title
				category
				hero {
					childImageSharp {
						fluid(maxWidth: 800, quality: 100) {
							...GatsbyImageSharpFluid_tracedSVG
						}
					}
				}
			}
		}
	}
`;
